<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Vehicle Routes
            <v-spacer></v-spacer>
            <add-button
              @action="
                (form.dialog = true),
                  getVehicles(),
                  $refs.form ? $refs.form.reset() : ''
              "
              permission="route-create"
            >
              Add New Route
            </add-button>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :server-items-length="form.items.meta.total"
            :items="form.items.data"
            :loading="form.loading"
            :footer-props="footerProps"
            :options.sync="pagination"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <span v-if="item.vehicles.length > 0">
                    <v-chip
                      small
                      v-for="(vehicle, index) in item.vehicles"
                      :key="vehicle.id"
                      :class="index === 0 ?'success white--text mr-2' : 'warning white--text mr-2'"
                      style="margin-top: 5px;"
                      >
                      <span v-if="index ===0">
                        Primary : {{ vehicle.name }} 
                      </span>
  
                      <span v-else>
                        Secondary : {{ vehicle.name }}
                      </span>
                    </v-chip>

                  </span>
                  <span v-else>-</span>
                </td>
                <td>
                  <center>{{ item.total_destinations }}</center>
                </td>
                <td
                  class="text-xs-right"
                  style="padding: 0 9px !important; min-width: 100px"
                >
                  <div class="btn_group">
                    <edit-button
                      permission="route-update"
                      @agree="
                        form.edit(item), getVehicles(), selectedVehicle(item)
                      "
                    />
                    <delete-button
                      permission="route-delete"
                      @agree="form.delete(item.id)"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>

      <v-dialog v-model="form.dialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <br />
              <v-layout row wrap class="pb-1">
                <v-flex xs12>
                  <v-text-field
                    outlined
                    dense
                    autocomplete="off"
                    label="Route name*"
                    required
                    class="pa-0 pl-2"
                    v-model="form.name"
                    name="route_name"
                    :error-messages="form.errors.get('name')"
                    :rules="[(v) => !!v || 'Route Name Field is required']"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    outlined
                    dense
                    v-model="form.vehicle"
                    :error-messages="form.errors.get('vehicle_id')"
                    :items="vehicles"
                    label="Select Vehicles"
                    multiple
                    class="pa-0 pl-2"
                  >
                    <v-list-item slot="prepend-item" ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            form.vehicle.length > 0 ? 'indigo darken-4' : ''
                          "
                          >{{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" class="mt-2"></v-divider>
                    <v-divider slot="append-item" class="mb-2"></v-divider>
                  </v-select>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<style scoped></style>
<script>
import Form from "@/library/Form";

import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  name: "VehicleComponent",
  components: {
    // RemoveDialog
  },
  data: () => ({
    valid: true,
    lazy: false,
    form: new Form(
      {
        name: "",
        vehicle_id: "",
        vehicle: [],
      },
      "api/route"
    ),
    headers: [
      { text: "#", align: "left", value: "sno", sortable: false, width: 10 },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
        width: 450,
      },
      { text: "Vehicle", align: "left", value: "serial", sortable: false },
      {
        text: "Locations",
        align: "left",
        value: "type",
        sortable: false,
        width: 80,
      },
      { text: "Action", align: "right", sortable: false, width: 100 },
    ],
    vehicles: [],
    rowsPerPageItems: [5, 10, 25, 50, 75],
    pagination: {
      rowsPerPage: 10,
    },
    drivers: [],
    search: "",
  }),
  watch: {
    pagination: function() {
      this.get();
    },
  },
  computed: {
    selectAllVehicles() {
      return this.form.vehicle.length === this.vehicles.length;
    },
    selectFewVehicles() {
      return this.form.vehicle.length > 0 && !this.selectAllVehicles;
    },
    icon() {
      if (this.selectAllVehicles) return "check_box";
      if (this.selectFewVehicles) return "indeterminate_check_box";
      return "add_box";
    },
  },
  mounted() {
    // this.get();
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.store();
      }
    },
    get(params) {
      let extraParams = "";
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        // this.pagination.totalItems = data.meta.total
      });
    },

    store() {
      this.form.vehicle_id = this.form.vehicle.join(",");
      this.form.store();
    },
    selectedVehicle(item) {
      this.form.vehicle = item.vehicles.map(function(v) {
        return v.id;
      });
    },
    getVehicles() {
      this.$rest.get("/api/vehicle").then(({ data }) => {
        this.vehicles = data.data.map((veh) => {
          return { text: veh.name, value: veh.id };
        });
      });
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectAllVehicles) {
          this.form.vehicle = [];
        } else {
          this.form.vehicle = this.vehicles.map((grade) => {
            return grade.value;
          });
        }
      });
    },
  },
};
</script>
